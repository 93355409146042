<template>
	<div class="partial partial--artist">
		<button-gallery v-on:click.native="show = !show" v-bind:class="{ 'button-gallery--open': show }"></button-gallery>
		<content-band>
			<div slot="img-top" class="content-band__image content-band__image-short" :style="bioImage"></div>
			<h3 class="content-band__artist-profile">Artist Profile</h3>
			<h2 class="content-band__title">Derek Raulerson</h2>
			<p>Derek, a graduate of Ringling College of Art and Design, began his career as an illustrator before becoming a full-time tattoo artist 9 years ago. Derek offers a wide variety of tattoo styles ranging from color realism to new school. In keeping with his illustration background, Derek has an affinity for tattooing comic book and science fiction characters.</p>
			
		</content-band>

		<transition class="transition" name="fade" mode="out-in">
			<div class="artist-container" v-if="show">
				<artists></artists>
			</div>
		</transition>

		<image-grid :images="images"></image-grid>
	</div>
</template>

<script>
// Components
import ContentBand from '@/components/ContentBand'
import ImageGrid from '@/components/ImageGrid'
import ButtonGallery from '@/components/ButtonGallery'
import Artists from '@/components/Artists'

// Export
export default {
	name: 'artist',
	components: {
		ContentBand,
		ImageGrid,
		ButtonGallery,
		Artists,
	},
	data: () => ({
		bioImage: {
			backgroundImage: 'url(' + require('@/assets/images/artist-derek.jpg') + ')'
		},
		images: [
			require('@/assets/images/derek/10337710_753163761392747_1846333298320128279_n.jpg'),
			require('@/assets/images/derek/10372791_10152538322813267_1527738874915527755_n.jpg'),
			require('@/assets/images/derek/10380999_767535726622217_3874985203538227003_n.jpg'),
			require('@/assets/images/derek/10418934_936867089689079_8217137602160179940_n.jpg'),
			require('@/assets/images/derek/10649907_827527410623048_1276520106862167037_n.jpg'),
			require('@/assets/images/derek/10982263_942710432438078_424453071622348709_n.jpg'),
			require('@/assets/images/derek/11081146_942711575771297_5340828345805454295_n.jpg'),
			require('@/assets/images/derek/11137135_941561622552959_5810903330771394786_n.jpg'),
			require('@/assets/images/derek/12122663_1041201059255681_5477216592892381656_n.jpg'),
			require('@/assets/images/derek/IMG_0196.jpg'),
			require('@/assets/images/derek/IMG_0608.jpg'),
			require('@/assets/images/derek/IMG_1238.jpg'),
			require('@/assets/images/derek/IMG_1603.jpg'),
			require('@/assets/images/derek/IMG_1855.jpg'),
			require('@/assets/images/derek/IMG_1966.jpg'),
			require('@/assets/images/derek/IMG_2229.jpg'),
			require('@/assets/images/derek/IMG_2810.jpg'),
			require('@/assets/images/derek/IMG_2811.jpg'),
			require('@/assets/images/derek/IMG_2882.jpg'),
			require('@/assets/images/derek/IMG_2893.jpg'),
			require('@/assets/images/derek/IMG_2918.jpg'),
			require('@/assets/images/derek/IMG_3962.jpg'),
			require('@/assets/images/derek/IMG_5119.jpg'),
			require('@/assets/images/derek/IMG_5129.jpg'),
			require('@/assets/images/derek/IMG_5350.jpg'),
			require('@/assets/images/derek/IMG_5901.jpg'),
			require('@/assets/images/derek/IMG_6276.jpg'),
			require('@/assets/images/derek/IMG_6633.jpg'),
			require('@/assets/images/derek/10252025_753163378059452_2673095765852401086_n.jpg'),
			require('@/assets/images/derek/IMG_8132.jpg'),
			require('@/assets/images/derek/IMG_8689.jpg'),
			require('@/assets/images/derek/IMG_9002.jpg'),
			require('@/assets/images/derek/IMG_9638.jpg'),
			require('@/assets/images/derek/297800_262280200481108_1070173634_n.jpg'),
			require('@/assets/images/derek/IMG_9945.jpg'),
			require('@/assets/images/derek/supermanaward.jpg'),
		],
		show: false
	}),
	metaInfo() {
		return {
			title: 'Derek Raulerson',
			titleTemplate: '%s | Mike Parsons Ink',
		}
	},
}
</script>

<style lang="scss" scoped>

	.artist-container {
		position: fixed;
		top: 0;
		left:0;
		right:0;
		bottom:0;
		z-index: 32;
		overflow: auto;
		transform: translateY(0);
		transition: transform 400ms $easing-material, opacity 200ms $easing-material;

		&.fade-enter-active {
			//transition: opacity .5s;
			top: 100%;
			transform: translateY(100%);
			
		}

		&.fade-leave-active {
			opacity: 0;
			transform: translateY(100%);
		}
	}

	
</style>
